import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Box, Grid, Typography } from '@mui/material';
import {
  Card, CardActionArea, CardContent, Divider, Radio,
} from '@doit/pcnt-react-ui-library';
import { moneyFormatterWithoutDecimals } from '../utils/formatterUtil';
import i18n from '../common/i18n';
import { fillMessageWith } from '../utils/functionsUtil';

const InstallmentCard = ({
  installment, isSelected, onSelect, originalAmount,
}) => {
  const resolveInstallmentsMessage = () => {
    let label;
    if (installment.installments === 1) {
      label = parse(
        fillMessageWith(
          i18n.InstallmentCard.installment,
          moneyFormatterWithoutDecimals(installment.amount),
        ),
      );
    } else {
      label = parse(
        fillMessageWith(
          i18n.InstallmentCard.installments,
          installment.installments,
          moneyFormatterWithoutDecimals(installment.amount),
        ),
      );
    }
    return label;
  };

  const resolveSaveMessage = () => parse(
    fillMessageWith(
      i18n.InstallmentCard.save,
      moneyFormatterWithoutDecimals(originalAmount - installment.amount),
    ),
  );

  const resolveDiscountMessage = () =>
    `${(((originalAmount - installment.amount) / originalAmount) * 100).toFixed(0)}%`;

  return (
    <Box className="container-ribbon" sx={{ textAlign: 'left' }}>
      <Card variant="outlined" className={isSelected ? 'installment-card-selected' : ''}>
        <CardActionArea disableRipple onClick={() => onSelect(installment)}>
          <CardContent>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={10}>
                <Box display="flex" alignItems="flex-start">
                  <Radio className="installment-card-radio" value="small" checked={isSelected} />
                  <Typography>
                    {resolveInstallmentsMessage()}
                  </Typography>
                  {originalAmount && (
                    <Typography className="installment-card-previous-price">
                      {moneyFormatterWithoutDecimals(originalAmount)}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={2}>
                {originalAmount && (
                  <Box className="promo-ribbon position-ribbon">
                    <Typography sx={{ fontSize: '12px' }}>{i18n.InstallmentCard.discount}</Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>{resolveDiscountMessage()}</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
            {originalAmount && (
              <Grid container>
                <Grid item xs={12}>
                  <Box ml={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className="installment-card-save">
                      {resolveSaveMessage()}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
            {isSelected && (
              <Grid container mt={1}>
                <Grid item xs={12}>
                  <Divider />
                  <Box
                    ml={6}
                    mt={1}
                    sx={{
                      display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left',
                    }}
                  >
                    <Grid container columnSpacing={{ xs: 6 }}>
                      <Grid item xs={1}>
                        <Typography className="installment-card-detail">{i18n.InstallmentCard.interests}</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className="installment-card-detail">
                          {moneyFormatterWithoutDecimals(installment.interests)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container columnSpacing={{ xs: 6 }}>
                      <Grid item xs={1}>
                        <Typography className="installment-card-detail">{i18n.InstallmentCard.IVA}</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className="installment-card-detail">
                          {moneyFormatterWithoutDecimals(installment.tax)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};

InstallmentCard.propTypes = {
  installment: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    installments: PropTypes.number.isRequired,
    interests: PropTypes.number.isRequired,
    tax: PropTypes.number.isRequired,
  }).isRequired,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  originalAmount: PropTypes.number,
};

InstallmentCard.defaultProps = {
  isSelected: false,
  onSelect: () => {},
  originalAmount: undefined,
};

export default InstallmentCard;
